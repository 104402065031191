import loadable from '@loadable/component';
import { WithLazyHydration } from '../../../../WithLazyHydration';

const ContentTakeOverExpandedForImageLoadable = loadable(
  () => import('./ContentTakeOverExpandedForImage'),
  {
    resolveComponent: components => components.ContentTakeOverExpandedForImage
  }
);

export const ContentTakeOverExpandedForImage = WithLazyHydration(
  ContentTakeOverExpandedForImageLoadable,
  {
    name: 'ContentTakeOverExpandedForImage'
  }
);
