import loadable from '@loadable/component';
import { WithLazyHydration } from '../../../../WithLazyHydration';

const ContentTakeOverExpandedForPhotographerLoadable = loadable(
  () => import('./ContentTakeOverExpandedForPhotographer'),
  {
    resolveComponent: components =>
      components.ContentTakeOverExpandedForPhotographer
  }
);

export const ContentTakeOverExpandedForPhotographer = WithLazyHydration(
  ContentTakeOverExpandedForPhotographerLoadable,
  {
    name: 'ContentTakeOverExpandedForPhotographer'
  }
);
