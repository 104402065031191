import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ContentTakeOverExpandedForImage } from './ContentTakeOverExpandedForImage';
import { ContentTakeOverExpandedForPhotographer } from './ContentTakeOverExpandedForPhotographer';

const OPEN_DROPDOWN_CLASS = 'open-dropdown';

const styles = {
  content: css`
    cursor: pointer;
  `
};

export const ContentTakeOver = ({ children, imageEntity, cards }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expand = e => {
    const thisUrl = new URL(window.location.href).pathname;
    if (
      typeof e.target.href === 'undefined' ||
      new URL(e.target.href).pathname === thisUrl
    ) {
      e.preventDefault();
      e.stopPropagation();
      setIsExpanded(true);
      document.body.classList.add(OPEN_DROPDOWN_CLASS);
    }
  };
  const collapse = () => {
    setIsExpanded(false);
    document.body.classList.remove(OPEN_DROPDOWN_CLASS);
  };

  const ExpandedComponent = imageEntity
    ? ContentTakeOverExpandedForImage
    : ContentTakeOverExpandedForPhotographer;

  return (
    <>
      <div
        css={styles.content}
        onClick={expand}
        onKeyPress={expand}
        role="button"
        tabIndex="0"
      >
        {children}
      </div>

      {isExpanded ? (
        <ExpandedComponent
          imageEntity={imageEntity}
          cards={cards}
          closeCb={collapse}
        />
      ) : (
        <div />
      )}
    </>
  );
};
ContentTakeOver.defaultProps = {
  imageEntity: undefined,
  cards: undefined
};

ContentTakeOver.propTypes = {
  children: PropTypes.node.isRequired,
  imageEntity: PropTypes.objectOf(PropTypes.any),
  cards: PropTypes.arrayOf(PropTypes.any)
};
